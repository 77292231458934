<template>
  <view-wrapper title="询价单">
    <template #header>
      <a-tabs type="card" v-model:activeKey="tabs.inquiryStatus" @change="tabs.click" class="tabs-card-self">
        <a-tab-pane
          :key="item.code"
          :disabled="inquiryLoading"
          :tab="item.message"
          v-for="item in statuData"
        ></a-tab-pane>
      </a-tabs>
    </template>
    <div class="bg-[#fff] p-[24px]">
      <form-query :model="form.data" @search="form.search">
        <a-row>
          <a-col :span="7">
            <a-form-item label="询价单号">
              <a-input v-model:value="form.data.serialNo" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="VIN码">
              <a-input v-model:value="form.data.vin" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="品牌">
              <e-select
                placeholder="请选择"
                :ajax="{ get: { action: 'GET /common/brand' } }"
                :allowClear="true"
                show-search
                :filter-option="filterOption"
                v-model="form.data.vehicleBrand"
                :props="{ label: 'name', value: 'name' }"
                value-key="id"
              ></e-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <a-form-item label="车牌号">
              <a-input v-model:value="form.data.vehiclePlateNo" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="询价人">
              <a-input v-model:value="form.data.preparedBy" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="3" :offset="7" class="flex justify-end">
            <a-button @click="form.init" class="mr-[9px]">重置</a-button>
          </a-col>
        </a-row>
      </form-query>

      <e-table
        :columns="inquiryColumns"
        :data-source="inquiryData"
        pagination
        :paging="inquiryActions.getPaging()"
        :method="tableRun"
        :loading="inquiryLoading"
        row-key="id"
      >
        <template #operation="{ record }">
          <span class="operation-model">
            <a
              class="text-primary"
              @click="inquiryDetailClick(record)"
              v-if="tabs.inquiryStatus === 'C'"
              style="margin-right: 6px"
              >查看</a
            >
            <a
              class="text-primary"
              @click="inquirySaveClick(record)"
              v-if="tabs.inquiryStatus === 'P'"
              style="margin-right: 6px"
              >查看</a
            >
            <a class="text-primary" v-if="record.isDelete && tabs.inquiryStatus === 'C'" @click="reCallClick(record.id)"
              >撤回</a
            >
            <a-button
              type="link"
              class="text-primary"
              :disabled="deleteInquiryLoading"
              v-if="tabs.inquiryStatus === 'P'"
              @click="deleteClick(record.id)"
              >删除</a-button
            >
          </span>
        </template>
      </e-table>
    </div>
  </view-wrapper>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAjax, useTable2, formatDate } from '@vue-mfe/utils'

import { Tabs as ATabs, TabPane as ATabPane, Row as ARow, Col as ACol } from 'ant-design-vue'

import type { ColumnProps } from 'ant-design-vue/es/table/interface'

const route = useRoute()
const router = useRouter()
const { data: statuData } = useAjax(
  {
    action: 'GET /common/lookup/IQYSTAT'
  }
)
const tabs = reactive(
  {
    inquiryStatus: 'C',
    click () {
      inquiryActions.setPaging({ pageIndex: 1 })
      tableRun()
    }
  }
)
const filterOption = (input: string, option: any) => {
  return option.value.toLowerCase().includes(input.toLowerCase())
}
const form = reactive(
  {
    data: {
      serialNo: '',
      vin: '',
      vehicleBrand: '',
      vehiclePlateNo: '',
      preparedBy: '',
      commitBy: ''
    },
    init () {
      form.data.serialNo = ''
      form.data.vin = ''
      form.data.vehicleBrand = ''
      form.data.vehiclePlateNo = ''
      form.data.preparedBy = ''
      form.data.commitBy = ''
      // tableRun()
    },
    search () {
      tableRun()
    }
  }
)

const {
  data: inquiryData,
  loading: inquiryLoading,
  columns: inquiryColumns,
  run: tableRun,
  actions: inquiryActions
} = useTable2(
  {
    ajax: {
      get: {
        action: 'GET /client/inquiry',
        params: () => ({ ...form.data, inquiryStatus: tabs.inquiryStatus }),
        convert: {
          client (data) {
            return data.map(
              (item: any) => {
                if (Date.now() - Date.parse(item.commitDatetime) <= 120000 && item.quoteSupplierCount === 0) {
                  item.isDelete = true
                }
                return item
              }
            )
          }
        }
      }
    },
    columns: [
      { title: '序号', customRender: ({ index }) => index + 1 },
      { title: '询价单号', dataIndex: 'serialNo' },
      { title: 'VIN码', dataIndex: 'vin' },
      {
        title: '车型',
        customRender: ({ record }) =>
          record.vin
            ? `${record.vehicleBrand}${record.vehicleSubBrand ? ' ' + record.vehicleSubBrand : ''} ${
                record.vehicleSystem
              } ${record.vehicleYear} ${record.vehicleDisplacement}`
            : record.vehicleBrand
      },
      { title: '车牌号', dataIndex: 'vehiclePlateNo' },
      { title: '询价人', dataIndex: 'commitBy' },
      { title: '询价时间', dataIndex: 'commitDatetime', customRender: ({ text }) => formatDate(text, true) },
      { title: '报价商家数', dataIndex: 'quoteSupplierCount' },
      { title: '操作', slots: { customRender: 'operation' }, align: 'center' }
    ] as ColumnProps[]
  }
)
const inquiryDetailClick = (row: any) => {
  router.push({ path: `/client/purchase/inquiry/quotation`, query: { id: row.id } })
}
const inquirySaveClick = (row: any) => {
  router.push(`/client/purchase/inquiry/${row.id}`)
}
const reCallClick = (row: any) => {
  useAjax(
    {
      action: 'POST /client/inquiry/:inquiryId/over',
      params: row,
      success () {
        tableRun()
      }
    }
  )
}
const inquiryId = ref('')
const { run: deleteInquiry, loading: deleteInquiryLoading } = useAjax(
  {
    action: 'DELETE /client/inquiry/:inquiryId',
    params: () => inquiryId.value,
    success () {
      tableRun()
    },
    lazy: true
  }
)
const deleteClick = (id: any) => {
  inquiryId.value = id
  deleteInquiry()
}

onMounted(
  () => {
    if (route.query.inquiryStatus) tabs.inquiryStatus = route.query.inquiryStatus as string
  }
)
</script>

<style></style>
